export const haryanaTrendData = [
    {
        name: 'July',
        "BJP": 15,
        "INC": 30
    },
    {
        name: 'Aug',
        "BJP": 10,
        "INC": 12
    },
    {
        name: 'Sep',
        "BJP": 25,
        "INC": 7
    },
    {
        name: 'Oct',
        "BJP": 18,
        "INC": 9
    },
    {
        name: 'Nov',
        "BJP": 20,
        "INC": 2
    }
];

export const haryanaTableData = {
    "heading": ["", "BJP", "INC", "OTHERS"],
    "data": [
        ["Our Prediction", "37-46", "39-48", "3-5"],
        ["Actual Results", "48", "37", "5"],
        ["Dainik Bhaskar", "19-29", "44-54", "5-15"],
        ["Dhruv Research", "22-32", "50-64", "2-8"],
        ["Matrize", "18-24", "55-62", "5-14"]
    ]
};

export const chattisgarhRangeTableData = {
    "heading": ["", "BJP", "INC", "OTHERS"],
    "data": [
        ["Our Prediction", "43-47", "40-45", "0-1"],
        ["Actual Results", "54", "35", "1"],
        ["Axis My India", "36-46", "40-50", "1-5"],
        ["CVoter", "36-48", "41-53", "0-3"],
        ["Today's Chanakya", "25-41", "49-65", "0-3"],
        ["Matrize", "34-42", "44-52", "0-2"],
        ["Jan Ki Baat", "34-45", "42-53", "0-3"]
    ]
};

export const hpRangeTableData = {
    "heading": ["", "BJP", "INC", "OTHERS"],
    "data": [
        ["Our Prediction", "33-39", "27-32", "0"],
        ["Actual Results", "25", "40", "3"],
        ["Axis My India", "24-34", "30-40", "4-8"],
        ["CVoter", "33-41", "24-32", "0-4"],
        ["Today's Chanakya", "33", "33", "0-2"],
        ["Matrize", "35-40", "26-31", "0-3"],
        ["Jan Ki Baat", "32-40", "27-34", "1-2"]
    ]
};

export const kt23RangeTableData = {
    "heading": ["", "BJP", "INC", "JD(S)", "OTHERS"],
    "data": [
        ["Our Prediction", "54-57", "108-112", "14-18", "2-3"],
        ["Actual Results", "66", "135", "19", "4"],
        ["Axis My India", "71", "131", "22", "-"],
        ["CVoter", "83-95", "100-112", "21-29", "4"],
        ["Today's Chanakya", "92", "120", "12", "0"],
        ["Matrize", "86", "108", "28", "2"],
        ["Jan Ki Baat", "106", "98", "19", "1"]
    ]
};

export const kt24RangeTableData = {
    "heading": ["", "NDA", "INDIA"],
    "data": [
        ["Our Prediction", "19-22", "5-8"],
        ["Actual Results", "19", "9"],
        ["Axis My India", "23-25", "3-5"],
        ["CVoter", "23-25", "3-5"],
        ["Today's Chanakya", "20-28", "0-8"],
        ["Matrize", "21", "7"],
        ["Jan Ki Baat", "19-25", "4-8"]
    ]
};

export const mhRangeTableData = {
    "heading": ["", "NDA", "INDIA", "OTHERS"],
    "data": [
        ["Our Prediction", "30-38", "10-15", "0"],
        ["Actual Results", "17", "30", "1"],
        ["CVoter", "22-26", "23-25", "-"],
        ["Today's Chanakya", "33", "15", "-"],
        ["Matrize", "30-36", "13-19", "-"]
    ]
};

export const odAeRangeTableData = {
    "heading": ["", "BJP", "INC", "BJD", "OTHERS"],
    "data": [
        ["Our Prediction", "76-80", "0", "60-65", "2-4"],
        ["Actual Results", "78", "14", "51", "4"],
        ["Axis My India", "62-80", "5-8", "62-80", "-"]
    ]
};

export const odPeRangeTableData = {
    "heading": ["", "NDA", "INDIA", "BJD", "OTHERS"],
    "data": [
        ["Our Prediction", "17-19", "1", "-", "1"],
        ["Actual Results", "20", "1", "0", "0"],
        ["Axis My India", "18-20", "0-1", "0-2", "-"],
        ["CVoter", "17-19", "0-2", "1-3", "-"],
        ["Today's Chanakya", "16", "1", "4", "-"],
        ["Matrize", "9-12", "0-1", "7-10", "-"],
        ["Jan Ki Baat", "15-18", "0", "3-7", "-"]
    ]
};

export const allStateData = {
    "HR": {
        "AE": {
            "2024": {
                "summary": {
                    "Total Data Points": "10 K+",
                    "People Surveyed": "575",
                    "Number of Rounds": "5",
                    "Time taken for survey": "375 hrs"
                },
                "graphData": {
                    "trendGraphData": haryanaTrendData,
                    "rangeGraphData": haryanaTrendData,
                },
                "tableData": {
                    "rangeTableData": haryanaTableData,
                    "roundTableData": haryanaTableData
                }

            }
        },
        "PE": {
            "2022": {
                "summary": {
                    "Total Data Points": "12K+",
                    "People Surveyed": "612",
                    "Number of Rounds": "4",
                    "Time taken for survey": "370 hours"
                },
                "graphData": {
                    "trendGraphData": haryanaTrendData,
                    "rangeGraphData": haryanaTrendData,
                },
                "tableData": {
                    "rangeTableData": haryanaTableData,
                    "roundTableData": haryanaTableData
                }
            }
        }
    },
    "CH": {
        "AE": {
            "2023": {
                "summary": {},
                "graphData": {},
                "tableData": {
                    "rangeTableData": chattisgarhRangeTableData,
                    "roundTableData": chattisgarhRangeTableData
                }
            }
        }
    },
    "HP": {
        "AE": {
            "2022": {
                "summary": {},
                "tableData": {
                    "rangeTableData": hpRangeTableData,
                    "roundTableData": hpRangeTableData
                }
            }
        }
    },
    "KA": {
        "AE": {
            "2023": {
                "summary": {},
                "graphData": {},
                "tableData": {
                    "rangeTableData": kt23RangeTableData,
                    "roundTableData": kt23RangeTableData
                }
            }
        },
        "PE": {
            "2024": {
                "summary": {},
                "graphData": {},
                "tableData": {
                    "rangeTableData": kt24RangeTableData,
                    "roundTableData": kt24RangeTableData
                }
            }
        }
    },
    "MH": {
        "PE": {
            "2024": {
                "summary": {},
                "graphData": {},
                "tableData": {
                    "rangeTableData": mhRangeTableData,
                    "roundTableData": mhRangeTableData
                }
            }
        }
    },
    "OR": {
        "AE": {
            "2024": {
                "summary": {},
                "graphData": {},
                "tableData": {
                    "rangeTableData": odAeRangeTableData,
                    "roundTableData": odAeRangeTableData
                }
            }
        },
        "PE": {
            "2024": {
                "summary": {},
                "graphData": {},
                "tableData": {
                    "rangeTableData": odPeRangeTableData,
                    "roundTableData": odPeRangeTableData
                }
            }
        }
    }
}

export const serviceableStates = ["Andhra Pradesh", "Chhattisgarh", "Himachal Pradesh", "Haryana", "Jharkhand", "Jammu & Kashmir", "Karnataka", "Kerala", "Maharashtra", "Odisha", "Punjab", "Tamil Nadu", "Uttarakhand", "Goa"];

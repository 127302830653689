import React from "react";
import { Box, Flex, Heading } from "@chakra-ui/react";

const PageHeadings = (props) => {
    return (
        <Flex width="max-content" alignItems="end" flexDirection="column">
            <Heading letterSpacing="1px" fontSize={{base: "1.25rem", lg: "1.825rem", xl: "2rem"}} color="brand.100">{props.heading}</Heading>
        </Flex>
    )
}

export default PageHeadings
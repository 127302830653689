import React from "react"
import { Box, Flex, Text, Card, CardBody, RadioGroup, Radio, Stack, Link, Divider, FormLabel, Select, Heading, SimpleGrid, GridItem, IconButton, TableContainer, Table, Th, Td, Tr, Tbody, Thead, Img } from "@chakra-ui/react"
import PageHeadings from "../Components/PageHeadings"
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, Line, LineChart, ResponsiveContainer } from 'recharts';
import { allStateData } from "../Utils/data"
import DataUnav from "../Assets/DataUnavailable.jpg"
import BJP from "../Assets/BJP.svg"
import INC from "../Assets/INC.svg"
import AAP from "../Assets/AAP.png"
import JDS from "../Assets/JDS.webp"

const data = [
    { party: 'BJP', range: 10, seats_max: 12, color: "#f9a02b" },
    { party: 'INC', range: 14, seats_max: 16, color: "#009ACD" },
    { party: 'AAP', range: 4, seats_max: 8, color: "#009ADA" }
];

const CustomYAxisTick = ({ x, y, payload }) => {
    return (
        <g transform={`translate(${x},${y})`}>
            {/* {payload.value.party === "BJP" && <img src={BJP} alt="BJP" x={-30} y={-10} width={30} height={30} />}
            {payload.value.party === "INC" && <img src={INC} alt="INC" x={-30} y={-10} width={30} height={30} />}
            {payload.value.party === "AAP" && <img src={AAP} alt="AAP" x={-30} y={-10} width={30} height={30} />} */}
            <text x={-10} y={0} dy={6} textAnchor="end" fill="#333">
                {payload.value.party}
            </text>
        </g>
    );
};

class Results extends React.Component {
    constructor() {
        super()
        this.state = {
            electionType: "AE",
            year: "2023",
            state: "CH",
            mode: "table",
            election: ""
        }
    }
    render() {
        const onChange = (e) => {
            const { name, value } = e.target
            this.setState({ [name]: value })
        }

        const onChangeElection = (e) => {
            const { value } = e.target
            const election = value.split("-")
            this.setState({ state: election[0], electionType: election[1], year: election[2], election: value })
        }

        const resetOptions = () => {
            this.setState({ electionType: "AE", year: "2021" })
        }

        const changeMode = (value) => {
            this.setState({ mode: value })
        }

        return (
            <Box>
                <Flex paddingTop="0rem" gap="12" paddingBottom="6rem" margin={["0px", "0px 1rem", "0px 2rem", "0px 4rem"]}>
                    <Box width={{ base: "100%", xl: "100%" }} marginTop="0rem">
                        <Flex paddingTop="8rem" paddingBottom="6rem" >
                            <PageHeadings heading="About Us" />
                        </Flex>
                        <PageHeadings heading="Past Results" />
                        <Flex marginY="2rem" gap="3" flexWrap="wrap">
                            {/* <Box>
                                <FormLabel color="brand.700">Select State</FormLabel>
                                <Select maxW="300px" name="state" onChange={onChange} value={this.state.state}>
                                    <option value="HR">Haryana</option>
                                    <option value="CH">Chattisgarh</option>
                                    <option value="HP">Himachal Pradesh</option>
                                    <option value="JK">Jammu Kashmir</option>
                                    <option value="KT">Karnataka</option>
                                    <option value="MH">Maharashtra</option>
                                    <option value="OD">Odisha</option>
                                </Select>
                            </Box> */}
                            <Box>
                                {/* <FormLabel color="brand.700">Select Election</FormLabel> */}
                                <Select maxW="300px" name="election" onChange={onChangeElection} value={this.state.election}>
                                    <option value="CH-AE-2023">Chattisgarh AE (2023)</option>
                                    <option value="HP-AE-2022">Himachal Pradesh AE (2022)</option>
                                    {/* <option value="JK">Jammu Kashmir</option> */}
                                    <option value="KT-AE-2023">Karnataka AE (2023)</option>
                                    <option value="KT-PE-2024">Karnataka GE (2024)</option>
                                    <option value="MH-PE-2024">Maharashtra GE (2024)</option>
                                    <option value="OD-AE-2024">Odisha AE (2024)</option>
                                    <option value="OD-PE-2024">Odisha GE (2024)</option>
                                </Select>
                            </Box>
                            <Box display={{ base: "none", lg: "none" }}>
                                <FormLabel color="brand.700">Select Election Type</FormLabel>
                                <Select maxW="300px" name="electionType" onChange={onChange} value={this.state.electionType}>
                                    <option value="AE">Assembly Elections</option>
                                    <option value="PE">Parliamentary Elections</option>
                                    <option value="BP">By Poll Elections</option>
                                </Select>
                            </Box>
                            <Box display={{ base: "none", lg: "none" }}>
                                <FormLabel color="brand.700">Select Year</FormLabel>
                                <Select maxW="300px" name="year" onChange={onChange} value={this.state.year}>
                                    <option value="2024">2024</option>
                                    <option value="2023">2023</option>
                                    <option value="2022">2022</option>
                                </Select>
                            </Box>
                        </Flex>
                        {allStateData[this.state.state]?.[this.state.electionType]?.[this.state.year] ?
                            <Box>
                                {/* <Text color="brand.200" fontSize="1.25rem" fontFamily="heading">Overview Summary</Text>
                                <SimpleGrid columns={{ base: 1, md: 2, xl: 4 }} gap="3" marginBottom="6rem">
                                    {Object.entries(allStateData[this.state.state][this.state.electionType][this.state.year].summary).map(([key, value]) => {
                                        return (
                                            <GridItem>
                                                <Card textAlign="center" variant="outline" boxShadow="md">
                                                    <CardBody>
                                                        <Heading color="brand.100"><CountUp end={value.split(" ")[0]} duration={2} />{value.split(" ")?.[1]}</Heading>
                                                        <Text color="brand.700">{key}</Text>
                                                    </CardBody>
                                                </Card>
                                            </GridItem>
                                        )
                                    })}
                                </SimpleGrid> */}
                                {/* <Flex justifyContent="end" gap="3" marginBottom="2rem" alignItems="center">
                                    <Text fontSize="0.75rem" color="brand.100">Change Layout</Text>
                                    <IconButton
                                        colorScheme={this.state.mode === "graph" && 'blue'}
                                        color={this.state.mode === "graph" ? 'white' : 'brand.100'}
                                        onClick={() => changeMode("graph")}
                                        icon={<i className="fa fa-bar-chart"></i>}
                                    />
                                    <IconButton
                                        colorScheme={this.state.mode === "table" && 'blue'}
                                        color={this.state.mode === "table" ? 'white' : 'brand.100'}
                                        onClick={() => changeMode("table")}
                                        icon={<i className="fa fa-table"></i>}
                                    />
                                </Flex> */}
                                {this.state.mode === "graph" &&
                                    <Box>
                                        <Heading fontSize={{ base: "1.25rem", lg: "1.5rem" }} color="brand.100" marginBottom="1rem">Overall Seats Comparison</Heading>
                                        <Flex gap="3" marginBottom="6rem">
                                            <ResponsiveContainer width={735} height={400}>
                                                <BarChart
                                                    layout="vertical"
                                                    data={data}
                                                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                                                >
                                                    <XAxis type="number" />
                                                    <YAxis dataKey="party" type="category" />
                                                    <Bar
                                                        dataKey="range"
                                                        fill="#8884d8"
                                                        barSize={20}
                                                        shape={(props) => {
                                                            const { x, y, height, payload } = props;
                                                            const rangeWidth = payload.seats_max - payload.range;
                                                            const start = payload.range;
                                                            const end = payload.seats_max;
                                                            return (
                                                                <g>
                                                                    <rect
                                                                        x={x + start * 39}
                                                                        y={y}
                                                                        width={rangeWidth * 40}
                                                                        height={height}
                                                                        fill={payload.color}
                                                                    />
                                                                    <text
                                                                        x={x + start * 39 - 10}
                                                                        y={y + height / 2 + 5}
                                                                        fill="#000"
                                                                        fontSize={12}
                                                                        textAnchor="end"
                                                                    >
                                                                        {start}
                                                                    </text>
                                                                    <text
                                                                        x={x + start * 39 + rangeWidth * 39 + 10}
                                                                        y={y + height / 2 + 5}
                                                                        fill="#000"
                                                                        fontSize={12}
                                                                        textAnchor="start"
                                                                    >
                                                                        {end}
                                                                    </text>
                                                                </g>
                                                            );
                                                        }}
                                                    >
                                                    </Bar>
                                                </BarChart>
                                            </ResponsiveContainer>
                                        </Flex>
                                        <Box marginTop="2rem">
                                            <Heading color="brand.100" marginBottom="1rem">Round Wise Seats </Heading>
                                            <LineChart
                                                width={800}
                                                height={500}
                                                data={allStateData[this.state.state][this.state.electionType][this.state.year].graphData.trendGraphData}
                                            >
                                                <XAxis padding={{ left: 30, right: 30 }} dataKey="name" />
                                                <YAxis />
                                                <Tooltip />
                                                <Legend verticalAlign align="end" iconSize={30} />
                                                <Line type="monotone" dataKey="BJP" stroke="#f9a02b" fill="#f9a02b" strokeWidth={3} dot={{ r: 4 }} />
                                                <Line type="monotone" dataKey="INC" stroke="#009ACD" fill="#009ACD" strokeWidth={3} dot={{ r: 4 }} />
                                            </LineChart>
                                        </Box>
                                    </Box>
                                }
                                {this.state.mode === "table" &&
                                    <Flex gap="3" justifyContent="space-between" flexWrap="wrap">
                                        <TableContainer marginBottom="6rem" width="100%">
                                            <Heading fontSize={{ base: "1.5rem", lg: "1.75rem" }} color="brand.100" marginBottom="1rem">Overall Seats Comparison</Heading>
                                            <Table variant='simple'>
                                                <Thead backgroundColor="brand.500">
                                                    <Tr>
                                                        {allStateData[this.state.state][this.state.electionType][this.state.year].tableData.rangeTableData.heading.map(eachHeading => {
                                                            return <Th color="brand.100" border="1px" textAlign="center" fontSize="0.825rem">{eachHeading}</Th>
                                                        })}
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {allStateData[this.state.state][this.state.electionType][this.state.year].tableData.rangeTableData.data.map(eachRow => {
                                                        return (
                                                            <Tr>
                                                                {eachRow.map(eachCell => {
                                                                    return (
                                                                        <Td border="1px solid #D3D3D3">
                                                                            <Flex gap="3" justifyContent="center">
                                                                                {eachCell === "BJP" && <img src={BJP} alt="BJP" width="20rem" />}
                                                                                {eachCell === "INC" && <img src={INC} alt="INC" width="20rem" />}
                                                                                {eachCell === "AAP" && <img src={AAP} alt="AAP" width="20rem" />}
                                                                                {eachCell}
                                                                            </Flex>
                                                                        </Td>
                                                                    )
                                                                })}
                                                            </Tr>
                                                        )
                                                    })}
                                                </Tbody>
                                            </Table>
                                        </TableContainer>
                                        {/* <TableContainer width="100%">
                                            <Heading color="brand.100" marginBottom="1rem">Round Wise Seats</Heading>
                                            <Table variant='simple'>
                                                <Thead backgroundColor="brand.400" color="brand.100">
                                                    <Tr>
                                                        {allStateData[this.state.state][this.state.electionType][this.state.year].tableData.roundTableData.heading.map(eachHeading => {
                                                            return <Th textAlign="center" border="1px" fontSize="0.825rem">{eachHeading}</Th>
                                                        })}
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {allStateData[this.state.state][this.state.electionType][this.state.year].tableData.roundTableData.data.map(eachRow => {
                                                        return (
                                                            <Tr>
                                                                {eachRow.map(eachCell => {
                                                                    return (
                                                                        <Td border="1px solid #D3D3D3">
                                                                            <Flex gap="3" justifyContent="center">
                                                                                {eachCell === "BJP" && <img src={BJP} alt="BJP" width="20rem" />}
                                                                                {eachCell === "INC" && <img src={INC} alt="INC" width="20rem" />}
                                                                                {eachCell === "AAP" && <img src={AAP} alt="AAP" width="20rem" />}
                                                                                {eachCell === "JD(S)" && <img src={JDS} alt="JDS" width="20rem" />}
                                                                                {eachCell}
                                                                            </Flex>
                                                                        </Td>
                                                                    )
                                                                })}
                                                            </Tr>
                                                        )
                                                    })}
                                                </Tbody>
                                            </Table>
                                        </TableContainer> */}
                                    </Flex>
                                }
                            </Box> :
                            <Box>
                                <Img src={DataUnav} alt="kamakhya" />
                            </Box>
                        }
                    </Box>
                </Flex>
            </Box>
        )
    }
}

export default Results
import { Box, Flex, Heading, Text, Input, Button, CardBody, Card, Select, Table, Tr, Th, Thead, Tbody, Td, Img, TableContainer, GridItem, SimpleGrid, Alert } from "@chakra-ui/react";
import React from "react";
import Divider from "../Components/Divider";
import India from "@react-map/india"
import PageHeadings from "../Components/PageHeadings";
import AnimatedGraphics from "../Components/AnimatedGraphics";
import Results from "./Results";
import { mapData } from "../Data/MapData";
import { stateNames } from "../Utils/stateNames";
import Radar from "../Assets/Radar.svg"
import Decisions from "../Assets/Decisions.svg"
import ResultsVector from "../Assets/Results.svg"
import Security from "../Assets/Security.svg"
import Nothing from "../Assets/Nothing to Show.jpg"
import RightDots from "../Assets/RightDots.png"
import LeftDots from "../Assets/LeftDots.png"
import Lines from "../Assets/Lines.png"
import Newsletter from "../Assets/Newsletter.svg"
import IndiaMap from "../Components/IndianMap";
import CheckMark from "../Assets/checkmark.png"
import axios from "axios";
import { baseUrl } from "..";

const customStyle = {
    MH: { fill: 'red' }, // Maharashtra in red
    UP: { fill: 'blue' }, // Uttar Pradesh in blue
    TN: { fill: 'green' }, // Tamil Nadu in green
    // Add more states with their respective colors
  };

class HomePage extends React.Component {
    constructor() {
        super()
        this.state = {
            email: "",
            state: "MH",
            stateName: "Maharashtra",
            isStateValid: true,
            isLoading: false,
            alertMessage: "",
            status: "success"
        }
    }
    render() {

        const onChange = (e) => {
            const { name, value } = e.target
            this.setState({ [name]: value })
            if (name === "state") {
                this.setState({ stateName: stateNames[value], isStateValid: true })
            }
        }

        const subscribeSubmit = () => {
            this.setState({ isLoading: true })
            axios.post(`${baseUrl}/newsletter`, { email: this.state.email }, {
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(response => {
                this.setState({ alertMessage: "Thank you for subscribing to our newsletter", status: "success", isLoading: false })
                setTimeout(() => {
                    this.setState({ alertMessage: "" })
                }, 3000)
            }).catch(err => {
                this.setState({ alertMessage: "Some error occurred. Please try again later", status: "error", isLoading: false })
                setTimeout(() => {
                    this.setState({ alertMessage: "" })
                }, 3000)
            })
        }

        const stateSelect = (state) => {
            const isValid = Object.values(stateNames).includes(state)
            console.log (state)
            this.setState({ isStateValid: isValid, state: "-", stateName: "-" })
            if (isValid) {
                const stateCode = Object.keys(stateNames).find(key => stateNames[key] === state)
                console.log (stateCode)
                this.setState({ stateName: state, state: stateCode, isStateValid: isValid })
            }
        }

        const isDisabled = !this.state.email

        return (
            <div>
                <div className="home-background">
                    <Flex height="80%" alignItems="center" justifyContent="end" margin={["0px", "0px 1rem", "0px 2rem", "0px 4rem"]} direction="column">
                        <Text textAlign="center" fontSize={{ base: "0.75rem", lg: "1rem" }} color="brand.600" marginTop="2rem" marginBottom="1rem">
                            Welcome to
                        </Text>
                        <Heading textAlign="center" color="brand.500" fontSize={{ base: "1.5rem", lg: "2.25rem", xl: "4rem" }}>Kamakhya Analytics</Heading>
                        <Text textAlign="center" maxW="600px" fontSize={{ base: "0.75rem", lg: "1rem" }} color="brand.600" marginBottom="4rem">
                            Your trusted partner in political strategy and analytics. <br /><br /><br />
                            We help leaders, political parties, and organizations navigate the complexities of modern
                            politics through cutting-edge political consulting services. We enable you to make
                            meaningful decisions using data-driven insights into India's political landscape.
                        </Text>
                    </Flex>
                </div>
                {/* <Box marginY="6rem">
                    <AnimatedGraphics />
                </Box> */}
                <Box backgroundColor="#F6F8FB" paddingY="2rem">
                    <Flex justifyContent="end" marginRight="0.5rem" marginBottom={{ base: "1rem", xl: "0.5rem" }}>
                        <Img width={{ base: "80%", lg: "300px", xl: "450px" }} src={RightDots} alt="rightDots" />
                    </Flex>
                    <Box margin={["0px", "0px 1rem", "0px 2rem", "0px 4rem"]}>
                        <Heading color="brand.100" fontSize={{ base: "1.25rem", lg: "1.75rem", xl: "2rem" }} marginBottom={{ base: "1rem", lg: "1.5rem", xl: "2rem" }}>Why Kamakhya Analytics Foundation?</Heading>
                        <SimpleGrid columns={{ base: 1, md: 1, lg: 2, xl: 4 }} gap="5" marginBottom={{ base: "3rem", xl: "6rem" }}>
                            <GridItem>
                                <Card height="100%" boxShadow="lg" borderTopWidth="6px" borderColor="brand.500">
                                    <CardBody textAlign="center">
                                        <Img margin="auto" marginTop="1.5rem" src={ResultsVector} alt="result" />
                                        <Text fontWeight="600" color="brand.100" marginTop="1rem" marginBottom="0.5rem">Sharp Minds, Proven Results</Text>
                                        <Text textAlign="justify" color="brand.100">
                                            Our team consists of political strategists and data
                                            experts who have guided major campaigns to success.
                                            We don’t follow trends — we create them and lead the way.
                                        </Text>
                                    </CardBody>
                                </Card>
                            </GridItem>
                            <GridItem>
                                <Card boxShadow="lg" height="100%" borderTopWidth="6px" borderColor="brand.500">
                                    <CardBody textAlign="center">
                                        <Img margin="auto" marginTop="1.5rem" src={Decisions} alt="Decisions" />
                                        <Text fontWeight="600" color="brand.100" marginTop="1rem" marginBottom="0.5rem">Data-backed Decisions, No Guess Work</Text>
                                        <Text textAlign="justify" color="brand.100">
                                            Forget gut feelings. Every decision we make is based on hard data and analytics,
                                            keeping your campaign ahead at all times.
                                        </Text>
                                    </CardBody>
                                </Card>
                            </GridItem>
                            <GridItem>
                                <Card height="100%" boxShadow="lg" borderTopWidth="6px" borderColor="brand.500">
                                    <CardBody textAlign="center">
                                        <Img margin="auto" marginTop="1.5rem" src={Radar} alt="radar" />
                                        <Text fontWeight="600" color="brand.100" marginTop="1rem" marginBottom="0.5rem">From Local Battles to National Victories</Text>
                                        <Text textAlign="justify" color="brand.100">
                                            We scale with your ambition.
                                            Whether it’s local office or national prominence,
                                            we tailor strategies to help you succeed.
                                        </Text>
                                    </CardBody>
                                </Card>
                            </GridItem>
                            <GridItem>
                                <Card height="100%" boxShadow="lg" borderTopWidth="6px" borderColor="brand.500">
                                    <CardBody textAlign="center">
                                        <Img margin="auto" marginTop="1.5rem" src={Security} alt="Security" />
                                        <Text fontWeight="600" color="brand.100" marginTop="1rem" marginBottom="0.5rem">Discreet, Committed, and Reliable</Text>
                                        <Text textAlign="justify" color="brand.100">
                                            In politics, trust is everything.
                                            We ensure confidentiality and stay committed to your success — no shortcuts,
                                            no leaks.
                                        </Text>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </SimpleGrid>
                    </Box>
                    <Flex marginLeft="0.5rem">
                        <img src={LeftDots} alt="leftDots" />
                    </Flex>
                </Box>
                <Box>
                    <Flex justifyContent="end" marginTop="2rem" marginBottom={{ base: "2rem", xl: "0.5rem" }}>
                        <Img src={Lines} alt="lines" />
                    </Flex>
                    <Box margin={["0px", "0px 1rem", "0px 2rem", "0px 4rem"]}>
                        <Box marginBottom="2rem">
                            <Flex flexWrap="wrap">
                                <PageHeadings heading="Our Areas of Services" />
                            </Flex>
                            <Text color="brand.100" mt="1rem">
                                Visualize voter sentiment, behavior, and election trends through dynamic graphs,
                                interactive heat maps, and real-time dashboards.
                            </Text>
                        </Box>
                        <Flex flexDirection={{ base: "column", xl: "row" }} marginBottom={{ base: "2rem", xl: "1rem" }} gap="3">
                            <Box width={{ base: "100%", xl: "50%" }}>
                                {/* <Select width={{ base: "100%", lg: "50%" }} mb="3rem" onChange={onChange} value={this.state.state} name="state">
                                    <option value="AP">Andhra Pradesh</option>
                                    <option value="CT">Chattisgarh</option>
                                    <option value="GA">Goa</option>
                                    <option value="HP">Himachal Pradesh</option>
                                    <option value="HR">Haryana</option>
                                    <option value="JH">Jharkhand</option>
                                    <option value="JK">Jammu & Kashmir</option>
                                    <option value="KA">Karnataka</option>
                                    <option value="KL">Kerala</option>
                                    <option value="MH">Maharashtra</option>
                                    <option value="OR">Odisha</option>
                                    <option value="PB">Punjab</option>
                                    <option value="TN">Tamil Nadu</option>
                                    <option value="UT">Uttarakhand</option>
                                    <option style={{ display: "none" }} value="-"></option>
                                </Select> */}
                                <Box>
                                    <IndiaMap onStateClick={stateSelect} />
                                </Box>
                            </Box>
                            <Box flexGrow="1">
                                {this.state.state !== "" && this.state.isStateValid &&
                                    <Box>
                                        <Flex marginTop={{ lg: "-6rem", xl: "1rem" }} justifyContent="space-between" alignItems="center" marginBottom="2rem">
                                            <Text fontWeight="bold" fontSize="1.5rem" fontFamily="heading" >{this.state.stateName}</Text>
                                            {/* <Flex gap="3" ms="auto" alignItems="center">
                                                <Text style={{ textWrap: "nowrap" }}>Election type</Text>
                                                <Select>
                                                    <option value="all">All</option>
                                                    <option value="assembly">Assembly</option>
                                                    <option value="general">General</option>
                                                </Select>
                                            </Flex> */}
                                        </Flex>
                                        <SimpleGrid columns={{ base: 2, md: 2, xl: 3 }} gap="5" marginBottom="6rem">
                                            {Object.entries(mapData[this.state.state]).map(eachItem => {
                                                return (
                                                    <GridItem>
                                                        <Card height="100%" border="1px solid #CAD7E8AD">
                                                            <CardBody>
                                                                <Img src={require(`../Assets/${eachItem[1].image}`)} alt="samples" marginBottom="0.5rem" />
                                                                <Text fontWeight="bold" fontSize="2rem" style={{ textWrap: "nowrap" }} color="brand.300">{eachItem[1].value}</Text>
                                                                <Text fontSize="0.875rem" color="brand.100">{eachItem[1].name}</Text>
                                                            </CardBody>
                                                        </Card>
                                                    </GridItem>
                                                )
                                            })}
                                        </SimpleGrid>
                                    </Box>
                                }
                                {!this.state.isStateValid &&
                                    <div>
                                        <Flex alignItems="center" marginBottom="6rem" direction="column">
                                            <Img width="400px" src={Nothing} alt="kamakhya" />
                                            <Text color="brand.700">No stats to show.</Text>
                                        </Flex>
                                    </div>
                                }
                            </Box>
                        </Flex>
                    </Box>
                </Box>
                <Box backgroundColor="brand.200">
                    <Flex justifyContent="space-between" alignItems="center" flexDirection={{ base: "column", lg: "row" }} margin={["0px", "0px 1rem", "0px 2rem", "0px 4rem"]} flexWrap="wrap">
                        <Box marginY="3rem">
                            <Heading fontSize={{ base: "1.5rem", lg: "1.825rem", xl: "2rem" }} color="brand.600" >
                                Stay Informed: Subscribe to Our Newsletter
                            </Heading>
                            <Text maxWidth="500px" fontSize={{ base: "0.75rem", lg: "1rem" }} color="brand.600" marginY="1rem">
                                Want to stay ahead of the political curve?
                                Sign up for our newsletter and get the latest insights, trends, and
                                updates on political strategy and data analytics delivered to your inbox.
                            </Text>
                            <Flex alignItems="center" gap="2" mb="0.5rem">
                                <Img src={CheckMark} alt="kamakhya" />
                                <Text color="brand.600">
                                    Exclusive tips on campaign startegies
                                </Text>
                            </Flex>
                            <Flex alignItems="center" gap="2" mb="0.5rem">
                                <Img src={CheckMark} alt="kamakhya" />
                                <Text color="brand.600">
                                    Latest trends in voter behaviour analysis
                                </Text>
                            </Flex>
                            <Flex alignItems="center" gap="2" mb="1rem">
                                <Img src={CheckMark} alt="kamakhya" />
                                <Text color="brand.600">
                                    Insights from political experts across the country
                                </Text>
                            </Flex>
                            <Flex flexWrap="wrap" alignItems="center" gap="1" mb="1rem">
                                <Text fontWeight="bold" color="brand.600">
                                    Join the Kamakhya community today
                                </Text>
                                <Text color="brand.600">
                                    and never miss a beat in the fast-paced world of politics!
                                </Text>
                            </Flex>
                            <Flex gap="3" mb="0.5rem">
                                <Input fontSize={{ base: "0.75rem", lg: "1rem" }} value={this.state.email} name="email" onChange={onChange} bg="brand.1000" color="brand.100" placeholder='Enter your email' marginBottom="2" />
                                <Button isDisabled={isDisabled} onClick={subscribeSubmit} paddingX="2rem" fontWeight="normal" fontSize={{ base: "0.75rem", lg: "1rem" }} _hover={{ color: "brand.100" }} bgColor="brand.500" color="brand.600">
                                    Subscribe here
                                </Button>
                            </Flex>
                            {this.state.alertMessage &&
                                <Alert borderRadius="0.5rem" status={this.state.status}>
                                    {this.state.alertMessage}
                                </Alert>}
                        </Box>
                        <Flex justifyContent="center" marginY="2rem">
                            <Img src={Newsletter} alt="newsletter" />
                        </Flex>
                    </Flex>
                </Box>
            </div>
        )
    }
}

export default HomePage

import { Box, Flex, Heading, Img, Text } from "@chakra-ui/react"
import React from "react"
import { motion } from "framer-motion"
// import Graphic1 from "../Assets/Graphic1.jpg"
// import Graphic2 from "../Assets/Graphic2.jpg"

const items = [
    {
        name: "Heading 1",
        description: "Lorem Ipsum",
        image: "/Graphic1.jpg"
    },
    {
        name: "Heading 2",
        description: "Lorem Ipsum 2",
        image: "/Graphic2.jpg"
    }
]

class AnimatedGraphics extends React.Component {
    constructor() {
        super()
        this.state = {
            currentIndex: 0
        }
    }
    componentDidMount() {
        setInterval(() => {
            this.setState({ currentIndex: (this.state.currentIndex + 1) % items.length })
        }, 5000);
    }
    render() {
        return (
            <Flex flexDirection={{ base: "column", xl: "row" }} margin={["0px", "0px 1rem", "0px 2rem", "0px 4rem"]} justifyContent="space-between" alignItems='center' overflow="hidden">
                <motion.div
                    key={this.state.currentIndex}
                    initial={{ y: 100, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -100, opacity: 0 }}
                    transition={{ duration: 1 }}
                    style={{ width: '100%' }}
                >
                    <Flex flexDirection={{ base: "column", xl: "row" }} justifyContent="space-between" overflow="hidden" clipPath="inset(0)">
                        {/* <Box padding="1rem">
                            {this.state.currentIndex === 0 && <img src={Graphic1} alt="kamakhya" />}
                            {this.state.currentIndex === 1 && <img src={Graphic2} alt="kamakhya" />}
                        </Box> */}
                        <Box flex="1" textAlign="left" padding="1rem">
                            <Heading> {items[this.state.currentIndex].name} </Heading>
                            <Text>  {items[this.state.currentIndex].description}    </Text>
                        </Box>
                    </Flex>
                </motion.div>
            </Flex>
        )
    }
}

export default AnimatedGraphics
import React, { useState, useEffect } from "react";
import { MapContainer, GeoJSON } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import indiaStates from "../Data/india_state_geo.json";
import { serviceableStates } from "../Utils/data";
import { Box } from "@chakra-ui/react";

const IndiaMap = ({ onStateClick }) => {
    const [selectedState, setSelectedState] = useState("Maharashtra");
    const [hoveredState, setHoveredState] = useState(null);
    const [zoom, setZoom] = useState(4.4);
    const [mapHeight, setMapHeight] = useState("65vh")

    useEffect(() => {
        const width = window.innerWidth;
        console.log(width)
        if (width <= 320) {
            setZoom(4);
            setMapHeight("60vh");
        } else if (width <= 400) {
            setZoom(4);
            setMapHeight("65vh");
        } else if (width <= 900) {
            setZoom(5);
            setMapHeight("40vh");
        } else if (width <= 1280) {
            setZoom(3.5);
            setMapHeight("95vh");
        } else {
            setZoom(4.5);
            setMapHeight("115vh");
        }
        console.log(zoom, mapHeight)
    }, []);

    const stateStyle = (feature) => {
        const isServiceable = serviceableStates.includes(feature.properties.ST_NAME);
        const isSelected = feature.properties.ST_NAME === selectedState;
        const isHovered = feature.properties.ST_NAME === hoveredState;

        return {
            fillColor: isSelected ? "#004FB6" : isServiceable ? isHovered ? "#0070FF" : "#0070FF7A" : "#CAD7E88F",
            weight: 1,
            color: "#4D668766",
            fillOpacity: 1,
            opacity: 1,
        };
    };

    const onEachState = (feature, layer) => {
        const stateName = feature.properties.ST_NAME;
        layer.on("click", () => {
            if (serviceableStates.includes(stateName)) {
                setSelectedState(stateName);
                onStateClick(stateName);
            }
        });

        layer.on("mouseover", () => {
            setHoveredState(stateName);
        });

        layer.on("mouseout", () => {
            setHoveredState(null);
        });
    };

    return (
        <Box>
            {window.innerWidth <= 420 ?
                <MapContainer
                    center={[21.5937, 82.9629]}
                    zoom={4}
                    zoomControl={false}
                    style={{
                        height: "60vh",
                        width: "100%",
                        backgroundColor: "transparent",
                        border: "none",
                        outline: "none"
                    }}
                    scrollWheelZoom={false}
                    dragging={false}
                    doubleClickZoom={false}

                >
                    <GeoJSON
                        data={indiaStates}
                        style={stateStyle}
                        onEachFeature={onEachState}
                    />
                </MapContainer> : window.innerWidth <= 820 ?
                    <MapContainer
                        center={[21.5937, 82.9629]}
                        zoom={4.5}
                        zoomControl={false}
                        style={{
                            height: "80vh",
                            width: "100%",
                            backgroundColor: "transparent",
                            border: "none",
                            outline: "none"
                        }}
                        scrollWheelZoom={false}
                        dragging={false}
                        doubleClickZoom={false}

                    >
                        <GeoJSON
                            data={indiaStates}
                            style={stateStyle}
                            onEachFeature={onEachState}
                        />
                    </MapContainer> : window.innerWidth <= 1280 ?
                        <MapContainer
                            center={[21.5937, 82.9629]}
                            zoom={4.4}
                            zoomControl={false}
                            style={{
                                height: "60vh",
                                width: "90%",
                                backgroundColor: "transparent",
                                border: "none",
                                outline: "none"
                            }}
                            scrollWheelZoom={false}
                            dragging={false}
                            doubleClickZoom={false}

                        >
                            <GeoJSON
                                data={indiaStates}
                                style={stateStyle}
                                onEachFeature={onEachState}
                            />
                        </MapContainer> : <MapContainer
                            center={[21.5937, 82.9629]}
                            zoom={4.5}
                            zoomControl={false}
                            style={{
                                height: "115vh",
                                width: "100%",
                                backgroundColor: "transparent",
                                border: "none",
                                outline: "none"
                            }}
                            scrollWheelZoom={false}
                            dragging={false}
                            doubleClickZoom={false}

                        >
                            <GeoJSON
                                data={indiaStates}
                                style={stateStyle}
                                onEachFeature={onEachState}
                            />
                        </MapContainer>}
        </Box>


    );
};

export default IndiaMap;
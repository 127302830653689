import { Box, Card, CardBody, Flex, GridItem, Heading, Img, SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";
import AboutLanding from "../Assets/About Us page.png"
import Projects from "../Assets/Projects.svg"
import Strength from "../Assets/Strength.svg"
import People from "../Assets/People.svg"
import Data from "../Assets/Data.svg"
import Survey from "../Assets/Survey.svg"
import Opinion from "../Assets/Opinion.svg"
import Campaigns from "../Assets/Campaigns.svg"
import Policy from "../Assets/Policy.svg"
import PageHeadings from "../Components/PageHeadings"
import Team from "../Assets/Team.jpg"
import Divider from "../Components/Divider"
import TeamCarousel from "../Components/TeamCarousel"
import TeamMembers from "../Data/Team.json"
import TeamCard from "../Components/TeamCard"

class AboutUs extends React.Component {
    render() {
        return (
            <Box>
                <div className="about-background">
                    <Flex height={["100%", "110%", "100%"]} margin={["0px", "0px 1rem", "0px 2rem", "0px 4rem"]} alignItems="center" flexDirection={{ base: "column", xl: "row" }} justifyContent="center" gap={{ base: "3", xl: "36" }} marginTop={{ base: "6rem", lg: "0px" }} marginBottom="6rem">
                        <Box width={{ base: "60%", lg: "auto" }}>
                            <Img src={AboutLanding} alt="kamakhya" margin={{ base: "auto" }} />
                        </Box>
                        <Box marginTop={{ base: "0rem", lg: "5rem" }} marginBottom={{ base: "0rem", md: "4rem" }} width={{ base: "100%", xl: "40%" }}>
                            <PageHeadings heading="About" />
                            <Text color="brand.100" fontWeight="bold" marginY={["1rem", "0px 2rem", "0px 4rem"]}>
                                At Kamakhya Analytics Foundation, we are pioneers in political consulting.
                                We are here to provide dedicated strategic insights that shape the future
                                of Indian politics.
                            </Text>
                            <Text color="brand.100">
                                Our expertise lies in blending data-driven analysis with sharp political strategies to help leaders, 
                                parties, and organizations confidently navigate the complex political landscape.
                            </Text>
                        </Box>
                    </Flex>
                </div>
                <Box margin={["0px", "0px 1rem", "0px 2rem", "0px 4rem"]}>
                    <SimpleGrid columns={{ base: 1, md: 2, xl: 4 }} gap={{ base: "12", lg: "3" }} paddingY="6rem">
                        <GridItem>
                            <Flex justifyContent="center" alignItems="center" gap="3">
                                <Img src={Projects} alt="kamakhya" />
                                <Flex width="200px" direction="column">
                                    <Heading fontSize={{ base: "1.5rem", xl: "3rem" }} color="brand.100">28</Heading>
                                    <Text fontWeight="bold">Projects Worked On</Text>
                                </Flex>
                            </Flex>
                        </GridItem>
                        <GridItem>
                            <Flex justifyContent="center" alignItems="center" gap="3">
                                <Img src={Data} alt="kamakhya" />
                                <Flex width="200px" direction="column">
                                    <Heading fontSize={{ base: "1.5rem", xl: "3rem" }} color="brand.100">7.88 Cr</Heading>
                                    <Text fontWeight="bold">Data Points Collected</Text>
                                </Flex>
                            </Flex>
                        </GridItem>
                        <GridItem>
                            <Flex justifyContent="center" alignItems="center" gap="3">
                                <Img src={People} alt="kamakhya" />
                                <Flex width="200px" direction="column">
                                    <Heading fontSize={{ base: "1.5rem", xl: "3rem" }} color="brand.100">40L</Heading>
                                    <Text fontWeight="bold">People Surveyed</Text>
                                </Flex>
                            </Flex>
                        </GridItem>
                        <GridItem>
                            <Flex justifyContent="center" alignItems="center" gap="3">
                                <Img src={Strength} alt="kamakhya" />
                                <Flex width="200px" direction="column">
                                    <Heading fontSize={{ base: "1.5rem", xl: "3rem" }} color="brand.100">800+</Heading>
                                    <Text fontWeight="bold">Current Strength</Text>
                                </Flex>
                            </Flex>
                        </GridItem>
                    </SimpleGrid>
                    {/* <Flex justifyContent="center" marginBottom="2rem">
                        <PageHeadings heading="OUR TEAM" />
                    </Flex> */}
                    {/* <Box marginBottom="6rem">
                        <TeamCarousel>
                            {TeamMembers.map(eachMember => {
                                return (
                                    <TeamCard name={eachMember.name} twitter={eachMember.twitter} linkedin={eachMember.linkedin} gmail={eachMember.gmail} 
                                        designation={eachMember.designation} description={eachMember.description}
                                    />
                                )
                            })}
                        </TeamCarousel>
                    </Box>
                    <Box marginBottom="6rem">
                        <Img src={Team} alt="kamakhya" rounded="8" width={{ base: "100%", xl: "60%" }} margin="auto" />
                    </Box> */}
                </Box>

                <Box padding={["1rem", "1rem 1rem", "2rem 2rem", "4rem 4rem"]} bgColor="#F6F8FB">
                    <Box marginTop={{ base: "2rem", lg: "0rem" }} marginBottom="2rem">
                        <Flex flexWrap="wrap">
                            <PageHeadings heading="Our Services" />
                        </Flex>
                        <Text color="brand.100">
                            We provide tailored solutions to help you achieve your goals effectively and efficiently.
                        </Text>
                    </Box>
                    <SimpleGrid columns={{ base: 1, lg: 2 }} gap="6" marginBottom="2rem" >
                        <GridItem>
                            <Card variant="elevated" align="center" h="100%">
                                <CardBody>
                                    <Flex gap="6" flexDirection={{ base: "column-reverse", xl: "row" }}>
                                        <Box borderRadius="0.5rem" bgColor="#F1F7FF" h="100%" w="100%" p="2rem">
                                            <Img margin="auto" src={Survey} alt="kamakhya" maxWidth={{ base: "100%", lg: 214 }} />
                                        </Box>
                                        <Box>
                                            <Text color="brand.100" fontWeight="bold" mb={{ base: "0.25rem", xl: "1rem" }}>Nationwide Surveys</Text>
                                            <Text fontSize={{ base: "0.75rem", lg: "1rem" }} color="brand.200" textAlign="justify">
                                                We conduct large-scale surveys that gather essential data from voters across India.
                                                These surveys offer insights into voter preferences, regional issues, and
                                                emerging political trends, helping you shape a strategy grounded in real feedback.
                                            </Text>
                                        </Box>
                                    </Flex>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem>
                            <Card variant="elevated" align="center" h="100%">
                                <CardBody>
                                    <Flex gap="6" flexDirection={{ base: "column-reverse", xl: "row" }}>
                                        <Box borderRadius="0.5rem" bgColor="#F1F7FF" h="100%" w="100%" p="2rem">
                                            <Img margin="auto" src={Opinion} alt="kamakhya" maxWidth={{ base: "100%", lg: 214 }} />
                                        </Box>
                                        <Box>
                                            <Text color="brand.100" fontWeight="bold" mb={{ base: "0.25rem", xl: "1rem" }}>Public Opinion Studies</Text>
                                            <Text fontSize={{ base: "0.75rem", lg: "1rem" }} color="brand.200" textAlign="justify">
                                                Understanding the public mood is critical for success.
                                                Our opinion studies analyze how different demographic groups respond to policies,
                                                parties, and candidates, enabling you to adjust your messaging to
                                                align with voter sentiment.
                                            </Text>
                                        </Box>
                                    </Flex>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem>
                            <Card variant="elevated" align="center" h="100%">
                                <CardBody>
                                    <Flex gap="6" flexDirection={{ base: "column-reverse", xl: "row" }}>
                                        <Box borderRadius="0.5rem" bgColor="#F1F7FF" h="100%" w="100%" p="2rem">
                                            <Img margin="auto" src={Campaigns} alt="kamakhya" maxWidth={{ base: "100%", lg: 214 }} />
                                        </Box>
                                        <Box>
                                            <Text color="brand.100" fontWeight="bold" mb={{ base: "0.25rem", xl: "1rem" }}>Impact of Public Campaigns</Text>
                                            <Text fontSize={{ base: "0.75rem", lg: "1rem" }} color="brand.200" textAlign="justify">
                                                Are your public campaigns resonating with voters?
                                                We measure the effectiveness of political campaigns and media outreach,
                                                providing you with clear data on what’s working and where improvements can be made.
                                            </Text>
                                        </Box>
                                    </Flex>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem>
                            <Card variant="elevated" align="center" h="100%">
                                <CardBody>
                                    <Flex gap="6" flexDirection={{ base: "column-reverse", xl: "row" }}>
                                        <Box borderRadius="0.5rem" bgColor="#F1F7FF" h="100%" w="100%" p="2rem">
                                            <Img margin="auto" src={Policy} alt="kamakhya" maxWidth={{ base: "100%", lg: 214 }} />
                                        </Box>
                                        <Box>
                                            <Text color="brand.100" fontWeight="bold" mb={{ base: "0.25rem", xl: "1rem" }}>General Policy Research</Text>
                                            <Text fontSize={{ base: "0.75rem", lg: "1rem" }} color="brand.200" textAlign="justify">
                                                We offer deep insights into the social and economic impact of policies.
                                                Our research helps you craft policies that are both popular with the
                                                public and feasible to implement, ensuring long-term political success.
                                            </Text>
                                        </Box>
                                    </Flex>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </SimpleGrid>
                </Box>
            </Box>
        )
    }
}

export default AboutUs
import { Flex, Box, Select, Text, Tr, Tbody, Th, TableContainer, Table, Thead, Td, SimpleGrid, Img, GridItem } from "@chakra-ui/react";
import React, { createRef } from "react";
import PageHeadings from "../Components/PageHeadings";
import BJP from "../Assets/BJP.svg"
import INC from "../Assets/INC.svg"
import AAP from "../Assets/AAP.png"
import { allStateData } from "../Utils/data"
import Slider from "react-slick";
import JHAnimation from "../Data/JHAnimation.json"
import MHAnimation from "../Data/MHAnimation.json"
import Animation from "../Data/Animation.json"
import Lottie from "lottie-react";
import { stateNames } from "../Utils/stateNames";

const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{}}
            onClick={onClick}>
            <i className="bi bi-chevron-right"></i>
        </div>
    );
}

const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{}}
            onClick={onClick}>
            <i className="bi bi-arrow-right"></i>
        </div>
    );
}

const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "1px",
    arrows: true,
    indicator: true,
    autoplay: false,
    autoplaySpeed: 5000,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
            }
        }
    ]
};

class NewResults extends React.Component {
    constructor() {
        super()
        this.state = {
            electionType: "AE",
            year: "2023",
            state: "CH",
            election: "",
            stateName: "Chhattisgarh"
        }
        this.lottieRef = createRef();
    }

    render() {

        const onChangeElection = (e) => {
            const { value } = e.target
            const election = value.split("-")
            let stateName = stateNames[election[0]]
            if (election[0] == "CH")
                stateName = "Chhattisgarh"
            this.setState({ state: election[0], electionType: election[1], year: election[2], election: value, stateName })
        }

        const setToolTip = (tooltip) => {
            this.setState({ tooltip })
        }

        return (
            <div>
                <Box padding={["0px", "0px 1rem", "0px 2rem", "2rem 4rem"]} backgroundColor="brand.300">
                    <div className="sm:mx-3 slider-container">
                        <Slider {...settings}>
                            <Box paddingBottom={{ base: "0px", xl: "2rem" }}>
                                <Box marginTop="6rem" marginBottom="2rem">
                                    <Text textAlign="center" color="brand.600" fontSize={{ base: "1.25rem", xl: "1.75rem" }} fontWeight="bold">Jharkhand AE 2024 Exit Poll</Text>
                                </Box>
                                <SimpleGrid columns={{ base: 1, lg: 1 }} gap="3">
                                    <Flex justifyContent="center">
                                        <TableContainer borderRadius="0.5rem">
                                            <Table whiteSpace={{ base: "pre-wrap", lg: "nowrap" }} margin={{ base: "auto", xl: "none" }} size={{ base: "sm", lg: "md" }} borderRadius="lg" backgroundColor="brand.600" width={{ base: "80%", lg: "40%" }} variant='simple'>
                                                <Thead>
                                                    <Tr>
                                                        <Th color="brand.100" fontSize={{ base: "0.5rem", lg: "0.825rem" }}>Alliance</Th>
                                                        <Th color="brand.100" fontSize={{ base: "0.5rem", lg: "0.825rem" }}>Predicted Seats</Th>
                                                        <Th color="brand.100" fontSize={{ base: "0.5rem", lg: "0.825rem" }}>Predicted Vote Share</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    <Tr>
                                                        <Td>
                                                            <Flex gap="3" alignItems="center">
                                                                <Text>
                                                                    NDA
                                                                </Text>
                                                            </Flex>
                                                        </Td>
                                                        <Td>
                                                            35 - 43
                                                        </Td>
                                                        <Td>
                                                            42.33% - 48.33%
                                                        </Td>
                                                    </Tr>
                                                    <Tr>
                                                        <Td>
                                                            <Flex gap="3" alignItems="center">
                                                                <Text>
                                                                    INDI
                                                                </Text>
                                                            </Flex>
                                                        </Td>
                                                        <Td>
                                                            37 - 45
                                                        </Td>
                                                        <Td>
                                                            41.53% - 47.53%
                                                        </Td>
                                                    </Tr>
                                                    <Tr>
                                                        <Td borderBottom="none">
                                                            <Flex gap="3">
                                                                OTHERS
                                                            </Flex>
                                                        </Td>
                                                        <Td>
                                                            0 - 2
                                                        </Td>
                                                        <Td borderBottom="none">
                                                            7.14% - 13.14%
                                                        </Td>
                                                    </Tr>
                                                </Tbody>
                                            </Table>
                                        </TableContainer>
                                    </Flex>
                                    {/* <Box marginTop={{ base: "0px", xl: "-3rem" }} width={{ base: "100%", lg: "90%", xl: "80%" }}>
                                        <Lottie lottieRef={this.lottieRef} style={{ width: "100%", height: "100%" }} animationData={JHAnimation} />
                                    </Box> */}
                                </SimpleGrid>
                            </Box>
                            <Box paddingBottom={{ base: "0px", xl: "2rem" }}>
                                <Box marginTop="6rem" marginBottom="2rem">
                                    <Text textAlign="center" color="brand.600" fontSize={{ base: "1.25rem", xl: "1.75rem" }} fontWeight="bold">Maharashtra AE 2024 Exit Poll</Text>
                                </Box>
                                <SimpleGrid columns={{ base: 1, lg: 1 }} gap="3">
                                    <Flex justifyContent="center">
                                        <TableContainer borderRadius="0.5rem">
                                            <Table whiteSpace={{ base: "pre-wrap", lg: "nowrap" }} size={{ base: "sm", lg: "md" }} borderRadius="lg" backgroundColor="brand.600" margin={{ base: "auto", xl: "none" }} width={{ base: "80%", lg: "40%" }} variant='simple'>
                                                <Thead>
                                                    <Tr>
                                                        <Th color="brand.100" fontSize={{ base: "0.425rem", lg: "0.825rem" }}>Alliance</Th>
                                                        <Th color="brand.100" fontSize={{ base: "0.425rem", lg: "0.825rem" }}>Predicted Seats</Th>
                                                        <Th color="brand.100" fontSize={{ base: "0.425rem", lg: "0.825rem" }}>Predicted Vote Share</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    <Tr>
                                                        <Td>
                                                            <Flex gap="3" alignItems="center">
                                                                <Text>
                                                                    NDA
                                                                </Text>
                                                            </Flex>
                                                        </Td>
                                                        <Td>
                                                            165 -185
                                                        </Td>
                                                        <Td>
                                                            43% - 47%
                                                        </Td>
                                                    </Tr>
                                                    <Tr>
                                                        <Td>
                                                            <Flex gap="3" alignItems="center">
                                                                <Text>
                                                                    INDI
                                                                </Text>
                                                            </Flex>
                                                        </Td>
                                                        <Td>
                                                            73 - 91
                                                        </Td>
                                                        <Td>
                                                            35% - 40%
                                                        </Td>
                                                    </Tr>
                                                    <Tr>
                                                        <Td borderBottom="none">
                                                            <Flex gap="3">
                                                                OTHERS
                                                            </Flex>
                                                        </Td>
                                                        <Td>
                                                            10 - 23
                                                        </Td>
                                                        <Td borderBottom="none">
                                                            13% - 18%
                                                        </Td>
                                                    </Tr>
                                                </Tbody>
                                            </Table>
                                        </TableContainer>
                                    </Flex>
                                    {/* <Box marginTop={{ base: "0px", xl: "-3rem" }} width={{ base: "100%", lg: "90%", xl: "80%" }}>
                                        <Lottie style={{ width: "100%", height: "100%" }} animationData={MHAnimation} />
                                    </Box> */}
                                </SimpleGrid>
                            </Box>
                        </Slider>
                    </div>
                </Box>
                <Flex flexWrap="wrap" gap={{ base: "3", xl: "24" }}>
                    <Box display={{ base: "none", lg: "block" }} width={{ base: "100%", lg: "80%", xl: "40%" }}>
                        <Img src={require(`../Assets/Maps/${this.state.stateName}.png`)} alt="kamakhya" />
                    </Box>
                    <Box margin={{ base: "1rem", lg: "2rem" }}>
                        <Box marginTop="1rem">
                            <PageHeadings heading="Past results" />
                        </Box>
                        <Flex gap="3" marginY="1rem" alignItems="center">
                            <Select maxW="300px" name="election" onChange={onChangeElection} value={this.state.election}>
                                <option value="CH-AE-2023">Chhattisgarh AE (2023)</option>
                                <option value="HR-AE-2024">Haryana AE (2024)</option>
                                <option value="HP-AE-2022">Himachal Pradesh AE (2022)</option>
                                {/* <option value="JK">Jammu Kashmir</option> */}
                                <option value="KA-AE-2023">Karnataka AE (2023)</option>
                                <option value="KA-PE-2024">Karnataka GE (2024)</option>
                                <option value="MH-PE-2024">Maharashtra GE (2024)</option>
                                <option value="OR-AE-2024">Odisha AE (2024)</option>
                                <option value="OR-PE-2024">Odisha GE (2024)</option>
                            </Select>
                        </Flex>
                        <Table width="100%" size={{ base: "sm", lg: "md" }} marginBottom={{ base: "3rem", xl: "6rem" }} border="1px" borderColor="#EDF2F7" variant='simple'>
                            <Thead backgroundColor="#F5F6F6" color="brand.100">
                                <Tr padding="0.5rem">
                                    {allStateData[this.state.state][this.state.electionType][this.state.year].tableData.rangeTableData.heading.map(eachHeading => {
                                        return <Th color="brand.100" fontSize="0.825rem" width="max-content">
                                            <Flex gap="1" alignItems="center" width="max-content">
                                                {eachHeading === "BJP" && <img src={BJP} alt="BJP" width="20rem" />}
                                                {eachHeading === "INC" && <img src={INC} alt="INC" width="20rem" />}
                                                {eachHeading === "AAP" && <img src={AAP} alt="AAP" width="20rem" />}
                                                {eachHeading}
                                            </Flex>
                                        </Th>
                                    })}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {allStateData[this.state.state][this.state.electionType][this.state.year].tableData.rangeTableData.data.map(eachRow => {
                                    return (
                                        <Tr borderColor={eachRow[0] === "Our Prediction" && "#084FAA"} border={eachRow[0] === "Our Prediction" && "2px"} color={eachRow[0] === "Our Prediction" && "brand.600"} backgroundColor={eachRow[0] === "Our Prediction" && "brand.500" || eachRow[0] === "Actual Results" && "#F47A5529"}>
                                            {eachRow.map((eachCell, index) => {
                                                return (
                                                    <Td fontWeight={index === 0 && "600"}>
                                                        <Flex gap="3">
                                                            {eachCell}
                                                        </Flex>
                                                    </Td>
                                                )
                                            })}
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                        </Table>
                    </Box>
                </Flex>
            </div>
        )
    }
}

export default NewResults
import React from "react";
import { Box, Button, Card, CardBody, Flex, Heading, Input, Text, SimpleGrid, GridItem, Textarea, Alert, Spinner } from "@chakra-ui/react";
import { buttonStyleHover } from "../Styling/components";
import PageHeadings from "../Components/PageHeadings";
import Divider from "../Components/Divider"
import axios from "axios";
import { baseUrl } from "..";

class Contact extends React.Component {
    constructor() {
        super()
        this.state = {
            name: "",
            email: "",
            message: "",
            alertMessage: "",
            status: "success",
            isLoading: false
        }
    }
    render() {

        const onChange = (e) => {
            const { name, value } = e.target
            this.setState({ [name]: value })
        }

        const onSubmit = () => {
            const { email, name, message } = this.state
            this.setState({ isLoading: true })
            const data = {
                email, name, message
            }
            axios.post(`${baseUrl}/contact-us`, data, {
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(response => {
                this.setState({ alertMessage: "Thank you for contacting us. We will get back to you shortly", status: "success", isLoading: false })
                setTimeout(() => {
                    this.setState({ alertMessage: "" })
                }, 3000)
            }).catch(err => {
                this.setState({ alertMessage: "Some error occurred. Please try again later", status: "error", isLoading: false })
                setTimeout(() => {
                    this.setState({ alertMessage: "" })
                }, 3000)
            })
        }

        const isDisabled = !(this.state.email && this.state.name && this.state.message)

        return (
            <Box>
                <div className="contact-background">
                    <Flex height="100%" alignItems="center" justifyContent="end" margin={["0px", "0px 1rem", "0px 2rem", "0px 4rem"]} direction="column">
                        <Heading textAlign="center" color="brand.600" fontSize={{ base: "1rem", lg: "1.5rem", xl: "2rem" }}>Contact Kamakhya Analytics</Heading>
                        <Text textAlign="center" maxW="800px" fontSize={{ base: "0.75rem", lg: "1rem" }} color="brand.600" marginTop="1rem" marginBottom="6rem">
                            We’re here to help you navigate the complexities of political strategy with data-driven insights and cutting-edge solutions.
                            Whether you have a question, need a consultation, or want to collaborate,
                            our team can assist you.
                        </Text>
                    </Flex>
                </div>
                <Box margin={["0px", "0px 1rem", "0px 2rem", "0px 4rem"]}>
                    <Flex paddingTop="4rem" paddingBottom="2rem">
                        <PageHeadings heading="Get in touch" />
                    </Flex>
                    <SimpleGrid columns={{ base: 1, lg: 2 }} gap="12" marginBottom="12rem" >
                        <GridItem>
                            <Card border="2px" borderColor="brand.500" boxShadow="lg">
                                <CardBody textAlign="center" px={{ base: "2rem", xl: "4rem" }}>
                                    <Text fontWeight="600" fontSize={{ base: "1.125rem", lg: "1.5rem" }} color="brand.100" fontFamily="body" marginBottom="1rem">Send a message</Text>
                                    <Input fontSize={{ base: "0.75rem", lg: "1rem" }} onChange={onChange} value={this.state.name} name="name" placeholder="Name" marginBottom="4" />
                                    <Input fontSize={{ base: "0.75rem", lg: "1rem" }} onChange={onChange} value={this.state.email} name="email" placeholder="Email Address" marginBottom="4" />
                                    <Textarea resize="none" fontSize={{ base: "0.75rem", lg: "1rem" }} onChange={onChange} value={this.state.message} name="message" placeholder="Message" marginBottom="6" />
                                    <Button isDisabled={isDisabled} paddingX="4rem" fontWeight="normal" fontSize={{ base: "0.75rem", lg: "1rem" }} onClick={onSubmit} _hover={{ color: "brand.100" }} bgColor="brand.500" color="brand.600">
                                        {this.state.isLoading ? <Spinner /> : "Submit"}
                                    </Button>
                                    {this.state.alertMessage &&
                                        <Alert borderRadius="0.5rem" marginTop="1rem" status={this.state.status}>
                                            {this.state.alertMessage}
                                        </Alert>}

                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem>
                            <Box height="100%" marginBottom="2rem" >
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.3535694052302!2d80.25210767484097!3d12.94921268736411!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525d53205d6807%3A0x7d883fdecc43c8ce!2sRavi%20Towers!5e0!3m2!1sen!2sin!4v1725262019641!5m2!1sen!2sin"
                                    width="100%" height="100%" style={{ border: "0px", borderRadius: "1rem", margin: "auto" }} title="Office Location"
                                    allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                                </iframe>
                                <Flex justifyContent="space-between" gap={{ base: "3", xxl: "12" }} marginTop="1rem" flexWrap="wrap">
                                    <Box>
                                        <Text color="brand.100" fontWeight="bold" marginBottom="0.5rem" maxW="400px">
                                            Room No 28, Ravi Towers , 47th Bunder Street,
                                            Tamil Nadu Chennai-600001
                                        </Text>
                                    </Box>
                                    <Text color="brand.500">
                                        enquiry@kamakhya.org.in
                                    </Text>
                                </Flex>
                            </Box>
                        </GridItem>
                    </SimpleGrid>
                </Box>
                {/* <Box bgColor="#D5E7FF" padding={["1rem", "1rem 1rem", "2rem 2rem", "4rem 4rem"]}>
                    <Flex gap="6" justifyContent="space-between" flexWrap="wrap">
                        <Text maxW={600} fontWeight="bold" color="brand.100">
                            At Kamakhya Analytics Foundation,
                            we’re here for your questions and feedback—reach out for
                            consultations or service inquiries!
                        </Text>
                        <Button paddingX="4rem" fontWeight="normal" fontSize={{ base: "0.75rem", lg: "1rem" }} onClick={onSubmit} _hover={{ color: "brand.100" }} bgColor="brand.500" color="brand.600">
                            Talk with our experts
                        </Button>
                    </Flex>
                </Box> */}
            </Box>
        )
    }
}

export default Contact
import { Box, Flex, GridItem, Img, SimpleGrid, Text, Link, Button } from "@chakra-ui/react";
import React from "react";
import Logo from "../Assets/Logo.svg"
import { EmailIcon } from "@chakra-ui/icons";

class Footer extends React.Component {
    render() {
        return (
            <Box>
                <Box padding={["1rem 0px", "1rem", "1rem 2rem", "1rem 4rem"]} backgroundColor="#021938">
                    <SimpleGrid columns={{ base: 1, md: 1, xl: 3 }} gap="3" marginY="2rem">
                        <GridItem>
                            <Img width={{ base: "40%", lg: "auto" }} src={Logo} alt="kamakhya" marginBottom={{ base: "1rem", xl: "2rem" }} />
                            <Text fontSize={{ base: "0.75rem", lg: "1rem" }} color="brand.600" mb="2rem">
                                Kamakhya Analytics specializes in political consulting and empowering campaigns with data-driven insights.
                            </Text>
                            <Flex gap="3" flexDirection={{base: "row", xl: "column"}} alignItems={{base:"center", xl: "start"}} mb="1rem">
                                <Text fontWeight="bold" fontSize="1rem" color="brand.600">
                                    Follow Us
                                </Text>
                                <a target="_blank" href="https://x.com/kamakhya_X">
                                    <Flex justifyContent="center" alignItems="center" bgColor="brand.100" color="brand.600" p="2" borderRadius="50%" width="2.5rem" height="2.5rem">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
                                            <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                                        </svg>
                                    </Flex>
                                </a>
                            </Flex>
                        </GridItem>
                        <GridItem>
                            <Flex fontSize={{ base: "0.75rem", lg: "1rem" }} flexDirection={{ base: "row", xl: "column" }} gap={{ base: "6", lg: "none" }} flexWrap="wrap" ms={{base: "0px", xl: "10rem"}} textColor="brand.600">
                                <Link _hover={{ textDecoration: "none", color: "brand.400" }} href="/"><Text mb="1rem" fontFamily="heading">Home</Text></Link>
                                <Link _hover={{ textDecoration: "none", color: "brand.400" }} href="/about-us"><Text mb="1rem" fontFamily="heading">About Us</Text></Link>
                                {/* <Link _hover={{ textDecoration: "none", color: "brand.400" }} href="/blogs"><Text mb="1rem" fontFamily="heading">Blogs</Text></Link> */}
                                <Link _hover={{ textDecoration: "none", color: "brand.400" }} href="/results"><Text mb="1rem" fontFamily="heading">Results</Text></Link>
                                {/* <Link _hover={{ textDecoration: "none", color: "brand.400" }} href="/career"><Text mb="1rem" fontFamily="heading">Career</Text></Link> */}
                                <Link _hover={{ textDecoration: "none", color: "brand.400" }} href="/contact-us"><Text mb="1rem" fontFamily="heading">Contact Us</Text></Link>
                            </Flex>
                        </GridItem>
                        <GridItem>
                            <Text color="brand.600" fontWeight="bold" fontSize="1rem" mb="1rem">
                                Get in touch
                            </Text>
                            <Flex gap="3" marginBottom="1rem" alignItems="center">
                                <Box textAlign="center" bgColor="brand.100" color="brand.600" p="2" borderRadius="50%" width="2.5rem" height="2.5rem">
                                    <EmailIcon />
                                </Box>
                                <Text fontSize={{ base: "0.75rem", lg: "1rem" }} color="brand.600">enquiry@kamakhya.org.in</Text>
                            </Flex>
                            <Flex gap="3" marginBottom="1rem">
                                <Box textAlign="center" bgColor="brand.100" color="brand.600" px="4" py="2" borderRadius="50%" width="2.5rem" height="2.5rem">
                                    <i style={{ fontSize: "1rem" }} class="fa fa-map-marker"></i>
                                </Box>
                                <Text fontSize={{ base: "0.75rem", lg: "1rem" }} color="brand.600">
                                    First floor, Sai tower, no. 46 (old no. 370) Arcot Road, Kodambakkam, Chennai city corporation, Tamil Nadu, India - 600024
                                </Text>
                            </Flex>
                        </GridItem>
                    </SimpleGrid>
                </Box>
                <Box fontSize="0.82rem" bgColor="brand.100" color="brand.600" p="3" textAlign="center">
                    <i className="fa fa-copyright" style={{ marginRight: "0.5rem" }}></i>
                    2024 Kamakhya Analytics Foundation. All rights reserved.
                </Box>
            </Box>

        )
    }
}

export default Footer
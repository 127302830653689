import { extendTheme } from "@chakra-ui/react";
import "@fontsource/montserrat"
import "@fontsource/archivo"

export const theme = extendTheme ({
    colors: {
        brand: {
            100: "#001024",
            200: "#00275B",
            300: "#003780",
            400: "#004FB6",
            500: "#0070FF",
            600: "#FFFFFF",
            700: "#A0A0A0",
            800: "#827E7E",
            900: "#FAFAFA",
            1000: "#DAE5F4"
        }
    },
    fonts: {
        heading: `'Archivo', sans-serif`,
        body: `'Archivo', sans-serif`
    },
    spacing: {
        1: "0.5rem",
        2: "1rem",
        3: "2rem",
        4: "4rem"
    },
    breakpoints: {
        base: "0px",
        sm: "320px",
        md: "400px",
        lg: "769px",
        xl: "1024px",
        '2xl': "1200px"
    },
    heading: {
        sm: "1.2rem",
        md: "1.5rem",
        lg: "1.75rem",
        xl: "2rem"
    }
})
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Toolbar from './Components/Toolbar';
import HomePage from './Pages/HomePage';
import AboutUs from './Pages/AboutUs';
import Blogs from './Pages/Blogs';
import Career from './Pages/Career';
import Contact from './Pages/Contact';
import CareerApply from './Pages/CareerApply';
import BlogContent from './Pages/BlogContent'
import Footer from './Components/Footer';
import Results from './Pages/Results';
import NewResults from './Pages/NewResults';
import "./App.css"

class App extends React.Component {
  render() {
    return (
      <div>
        <Toolbar />
        <Routes>
          <Route path='/' element={<HomePage />} exact />
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/blogs' element={<Blogs />} />
          <Route path='/blogs/:id' element={<BlogContent />} />
          <Route path='/career' element={<Career />} />
          <Route path='/career/:id' element={<CareerApply />} />
          <Route path='/contact-us' element={<Contact />} />
          <Route path='/results' element={<NewResults />} />
        </Routes>
        <Footer />
      </div>
    )
  }
}

export default App;

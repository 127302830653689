export const mapData = {
    "AP": [
        {
            "name": "Total samples collected",
            "value": 38425,
            "image": "samples.png"
        },
        {
            "name": "Constituencies covered",
            "value": 41,
            "image": "constituencies.png"
        },
        {
            "name": "Total duration of surveys",
            "value": "39 days",
            "image": "calendar.png"
        },
        {
            "name": "Number of villages covered",
            "value": 752,
            "image": "villages.png"
        },
        {
            "name": "Number of polling agents",
            "value": 124,
            "image": "polling.png"
        }
    ],
    "CH": [
        {
            "name": "Total samples collected",
            "value": 316889,
            "image": "samples.png"
        },
        {
            "name": "Constituencies covered",
            "value": 88,
            "image": "constituencies.png"
        },
        {
            "name": "Total duration of surveys",
            "value": "251 days",
            "image": "calendar.png"
        },
        {
            "name": "Number of villages covered",
            "value": 1293,
            "image": "villages.png"
        },
        {
            "name": "Number of polling agents",
            "value": 498,
            "image": "polling.png"
        }
    ],
    "GA": [
        {
            "name": "Total samples collected",
            "value": 3470,
            "image": "samples.png"
        },
        {
            "name": "Constituencies covered",
            "value": 8,
            "image": "constituencies.png"
        },
        {
            "name": "Total duration of surveys",
            "value": "11 days",
            "image": "calendar.png"
        },
        {
            "name": "Number of villages covered",
            "value": 25,
            "image": "villages.png"
        },
        {
            "name": "Number of polling agents",
            "value": 27,
            "image": "polling.png"
        }
    ],
    "HP": [
        {
            "name": "Total samples collected",
            "value": 78301,
            "image": "samples.png"
        },
        {
            "name": "Constituencies covered",
            "value": 62,
            "image": "constituencies.png"
        },
        {
            "name": "Total duration of surveys",
            "value": "122 days",
            "image": "calendar.png"
        },
        {
            "name": "Number of villages covered",
            "value": 6,
            "image": "villages.png"
        },
        {
            "name": "Number of polling agents",
            "value": 129,
            "image": "polling.png"
        }
    ],
    "HR": [
        {
            "name": "Total samples collected",
            "value": 703580,
            "image": "samples.png"
        },
        {
            "name": "Constituencies covered",
            "value": 90,
            "image": "constituencies.png"
        },
        {
            "name": "Total duration of surveys",
            "value": "208 days",
            "image": "calendar.png"
        },
        {
            "name": "Number of villages covered",
            "value": 4461,
            "image": "villages.png"
        },
        {
            "name": "Number of polling agents",
            "value": 1265,
            "image": "polling.png"
        }
    ],
    "JH": [
        {
            "name": "Total samples collected",
            "value": 244447,
            "image": "samples.png"
        },
        {
            "name": "Constituencies covered",
            "value": 81,
            "image": "constituencies.png"
        },
        {
            "name": "Total duration of surveys",
            "value": "160 days",
            "image": "calendar.png"
        },
        {
            "name": "Number of villages covered",
            "value": 1524,
            "image": "villages.png"
        },
        {
            "name": "Number of polling agents",
            "value": 647,
            "image": "polling.png"
        }
    ],
    "JK": [
        {
            "name": "Total samples collected",
            "value": 38480,
            "image": "samples.png"
        },
        {
            "name": "Constituencies covered",
            "value": 27,
            "image": "constituencies.png"
        },
        {
            "name": "Total duration of surveys",
            "value": "71 days",
            "image": "calendar.png"
        },
        {
            "name": "Number of villages covered",
            "value": 401,
            "image": "villages.png"
        },
        {
            "name": "Number of polling agents",
            "value": 195,
            "image": "polling.png"
        }
    ],
    "KA": [
        {
            "name": "Total samples collected",
            "value": 700132,
            "image": "samples.png"
        },
        {
            "name": "Constituencies covered",
            "value": 222,
            "image": "constituencies.png"
        },
        {
            "name": "Total duration of surveys",
            "value": "288 days",
            "image": "calendar.png"
        },
        {
            "name": "Number of villages covered",
            "value": 4214,
            "image": "villages.png"
        },
        {
            "name": "Number of polling agents",
            "value": 592,
            "image": "polling.png"
        }
    ],
    "KL": [
        {
            "name": "Total samples collected",
            "value": 16845,
            "image": "samples.png"
        },
        {
            "name": "Constituencies covered",
            "value": 32,
            "image": "constituencies.png"
        },
        {
            "name": "Total duration of surveys",
            "value": "99 days",
            "image": "calendar.png"
        },
        {
            "name": "Number of villages covered",
            "value": 59,
            "image": "villages.png"
        },
        {
            "name": "Number of polling agents",
            "value": 64,
            "image": "polling.png"
        }
    ],
    "MH": [
        {
            "name": "Total samples collected",
            "value": 962946,
            "image": "samples.png"
        },
        {
            "name": "Constituencies covered",
            "value": 287,
            "image": "constituencies.png"
        },
        {
            "name": "Total duration of surveys",
            "value": "373 days",
            "image": "calendar.png"
        },
        {
            "name": "Number of villages covered",
            "value": 4264,
            "image": "villages.png"
        },
        {
            "name": "Number of polling agents",
            "value": 1837,
            "image": "polling.png"
        }
    ],
    "OR": [
        {
            "name": "Total samples collected",
            "value": 663569,
            "image": "samples.png"
        },
        {
            "name": "Constituencies covered",
            "value": 147,
            "image": "constituencies.png"
        },
        {
            "name": "Total duration of surveys",
            "value": "194 days",
            "image": "calendar.png"
        },
        {
            "name": "Number of villages covered",
            "value": 3259,
            "image": "villages.png"
        },
        {
            "name": "Number of polling agents",
            "value": 1140,
            "image": "polling.png"
        }
    ],
    "PB": [
        {
            "name": "Total samples collected",
            "value": 60527,
            "image": "samples.png"
        },
        {
            "name": "Constituencies covered",
            "value": 54,
            "image": "constituencies.png"
        },
        {
            "name": "Total duration of surveys",
            "value": "90 days",
            "image": "calendar.png"
        },
        {
            "name": "Number of villages covered",
            "value": 441,
            "image": "villages.png"
        },
        {
            "name": "Number of polling agents",
            "value": 214,
            "image": "polling.png"
        }
    ],
    "TN": [
        {
            "name": "Total samples collected",
            "value": 95512,
            "image": "samples.png"
        },
        {
            "name": "Constituencies covered",
            "value": 75,
            "image": "constituencies.png"
        },
        {
            "name": "Total duration of surveys",
            "value": "140 days",
            "image": "calendar.png"
        },
        {
            "name": "Number of villages covered",
            "value": 707,
            "image": "villages.png"
        },
        {
            "name": "Number of polling agents",
            "value": 200,
            "image": "polling.png"
        }
    ],
    "UT": [
        {
            "name": "Total samples collected",
            "value": 18688,
            "image": "samples.png"
        },
        {
            "name": "Constituencies covered",
            "value": 28,
            "image": "constituencies.png"
        },
        {
            "name": "Total duration of surveys",
            "value": "57 days",
            "image": "calendar.png"
        },
        {
            "name": "Number of villages covered",
            "value": 295,
            "image": "villages.png"
        },
        {
            "name": "Number of polling agents",
            "value": 80,
            "image": "polling.png"
        }
    ],
}
